import axios from "axios";

const baseUrl="https://backend.somee.com/api/producto/";

//const baseUrl="https://backend.bsite.net/api/producto/";
//const baseUrl = "https://localhost:44308/api/producto/";


export const peticionGetUtils = async ({busqueda, selected}) => {
    const { data } = await axios.get(baseUrl+String(busqueda)+'/'+selected).catch(error => console.log(error))
    return data
}

