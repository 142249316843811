import './App.css';
import axios from "axios";
import React, { useEffect, useState } from "react";
import { BarcodeScanner } from "dynamsoft-javascript-barcode";
import BarcodeScannerComponent from "./components/BarcodeScannerComponent.js";
import { peticionGetUtils } from "./utils/utils";
import mySound from './sounds/beep.mp3';
import useSound from 'use-sound';
import {  TailSpin } from 'react-loader-spinner'
function App() {
  const baseUrlSucursales = "https://backend.somee.com/api/establecimiento";

  //const baseUrlSucursales = "https://backend.bsite.net/api/establecimiento";
  //const baseUrlSucursales = "https://localhost:44308/api/establecimiento";
  let time_seconds = 5;
  
  const [showSpinner, setshowSpinner] = useState(false);


  const formatDate = (string) => {
    let options = { year: "numeric", month: "long", day: "numeric" };
    let fecha = new Date(string).toLocaleDateString("es-PE", options);
    let hora = new Date(string).toLocaleTimeString(navigator.language, {
      hour: "2-digit",
      minute: "2-digit",
    });
    return fecha + " | " + hora;
  };

  //#region Temporizador
  function paddedFormat(num) {
    return num < 10 ? "0" + num : num; 
}

function startCountDown(duration, element) {

  let secondsRemaining = duration;
  let min = 0;
  let sec = 0;

  let countInterval = setInterval(function () {

      min = parseInt(secondsRemaining / 60);
      sec = parseInt(secondsRemaining % 60);

      element.textContent = `${paddedFormat(min)}:${paddedFormat(sec)}`;

      secondsRemaining = secondsRemaining - 1;
      if (secondsRemaining < 0) { clearInterval(countInterval) 
      LimpiarPantalla()
      }; 
    }, 1000);
}


  //#endregion

  //#region "Sucursal"
  const [selected, setSelected] = useState();
  const [Sucursales, setSucursales] = useState([]);
  const [showComboSucursales, setShowComboSucursales] = useState([]);
  const [playSound] = useSound(mySound);


  const handleChange = (event) => {
    setBusqueda(event.target.value);
  };

  const handleClick = async (event) => {
    event.preventDefault();
    setCodigobarras(busqueda)
    // BuscarProducto(codigobarras)
    setBusqueda([])
  };
  const peticionGetSucursales = async () => {
    await axios
      .get(baseUrlSucursales)
      .then((response) => {
        setSucursales(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    peticionGetSucursales();
  }, []);
  
  const ComboChange = (event) => {
    setSelected(event.target.value);
    setShowComboSucursales((s) => !s)
    setShowComponente(true);
  };
//#endregion

//#region "Componente de codigo de barras"
  let [codigobarras, setCodigobarras] = useState([]);
  const [showScanner,setShowScanner]= useState(false);
  const [showScannerIMG,setShowScannerIMG]= useState(true);
  const [showKeyboard, setShowkeyboard] = useState(false);

  const [libLoaded,setLibLoaded]= useState();
  const [productos, setProductos] = useState([]);
  let [busqueda, setBusqueda] = useState([]);
  const [showComponente,setShowComponente]= useState(false);

  useEffect(()=>{
    componentDidMount();
})


useEffect(() => {
  if (codigobarras.length>0){
    playSound();
    if(codigobarras.length===8) codigobarras=codigobarras.substring(1,7)
    BuscarProducto(codigobarras)
  }
}, [codigobarras]);


const BuscarProducto = async (CodigoBarras)=> {
  setshowSpinner(true);
  const data = await peticionGetUtils({busqueda:CodigoBarras, selected:selected}).catch(error => console.log(error));
  setProductos(data);
  setshowSpinner(false);
  if(data.length<1) {
    alert('EL PRODUCTO NO EXISTE',"PRODUCTO");
  }
  else
  {
    setShowScannerIMG(false);
    console.log(showScannerIMG)
    let element = document.querySelector('#count-down-timer');
    element.textContent = `${paddedFormat(time_seconds)}`;
    startCountDown(--time_seconds, element);
  }
  setCodigobarras('');
}

const componentDidMount = async ()=> {
  try {
    await BarcodeScanner.loadWasm();
    setLibLoaded(true);
  } catch (ex) {
    alert(ex.message);
    throw ex;
  }
}
const appendMessage = (message) => {
  setCodigobarras(message.text)
  setShowScanner(false);
  return;
};

const LimpiarPantalla = () => {
  setProductos([]);
  time_seconds=5;
  setShowScannerIMG(true);
  return;
};
  
  //#endregion
  return (
    <div className="App"> 
         <img src="https://i.imgur.com/ZZHfIwm.png" alt="Logo" width="250" height="75"/>
         <select  id="Sucursal" className="form-select form-select-lg"
            onChange={ComboChange} 
            value={selected} style={{visibility: showComboSucursales ? "visible" : "hidden",position: "absolute",top: "13px",right: "55px",width: "300px",fontSize: "15px",}}>
            <option> Seleccione una sucursal </option>
            {Sucursales.map((sucurs, idx) => (
            <option key={idx} value={sucurs.id}>{" "}{sucurs.nombre}{" "}</option>
            ))}
          </select>
          <button className="btn btn-light btn-circle btn-sm" onClick={() =>  setShowComboSucursales((s) => !s)} style={{  position: 'absolute' ,right:'1px',top: '10px'}} type="button"> <img src="https://cdn-icons-png.flaticon.com/128/1040/1040221.png" alt='Configuracion' height ="35" width="35" /></button>

         {showComponente && <div>

          <div className="Spinner">
          <TailSpin radius="1" color="#1E90FF" ariaLabel="tail-spin-loading"  wrapperStyle={{}} wrapperClass="" visible={showSpinner}/>
          </div>
          
         {productos.map((producto, idx) => (
        <div style={{ textAlign: "center" }} key={idx}>
          <img className='rounded' src={`data:image/jpeg;base64,${producto.imagen}`} alt="Imagen" width="400" height="200" ></img>
          
          <p > {producto.codigo} </p>

          <b> {producto.descripcion} </b>

          <p> ${producto.precio.toFixed(2)} </p>
          
          <div >
            <b style={{ fontSize: "45px",color:"blue" }}> {" "} ${producto.precioPromocion.toFixed(2)}{" "} </b>
          </div>

          <b> Existencia: {producto.existencia}</b>

          <p style={{ textAlign: "center" }} > 
           <small  style={{ textAlign: "center" }}> Última Actualización: {formatDate(producto.fechaUltimaActualizacion)} </small>
          </p>

        </div>
      ))}
            <br></br>
            {!showScannerIMG || showScanner ? (<div></div>):(<img src={"https://cdn-icons-png.flaticon.com/512/5393/5393325.png"} alt={"Barcode"} width={"200"} height={"100"} />)}
      
      <div style={{marginLeft: "auto",marginRight:"auto"}}>
          {!libLoaded && <span>Cargando Verificador...</span>}

              {showScanner ?  (<BarcodeScannerComponent id={"UIElement"} appendMessage={appendMessage} > </BarcodeScannerComponent>):(<br></br>)}

            <br></br>
      </div>     

              <div>
                <button type="button2" className="button2" onClick={() => setShowScanner((s) => !s)} style={{ marginBottom: 20}}> 
                <img src="https://cdn-icons-png.flaticon.com/128/6815/6815132.png" alt='AbreCierraScanner' height ="35" width="35" />  </button>
                  <button style={{ marginBottom: 20 }} className="button2" height ="35" width="35" onClick={() => setShowkeyboard(!showKeyboard)} > 
                  <img src="https://cdn-icons-png.flaticon.com/128/8816/8816408.png" alt='AbreCierraTeclado' height ="35" width="35" /> </button>
                {showKeyboard && 
                <div className="input-group inputBuscar"> 
                  <input className="form-control inputBuscar" aria-label="Small" value={busqueda} onChange={handleChange} placeholder="Codigo de producto" />
                  <button className="btn btn-primary btn-lg" onClick={handleClick} type="button2"> Buscar </button>
             </div>}
            </div>

         
                         
         <p style={{visibility: "hidden"}} id="count-down-timer"></p>
         </div>}

    </div>
  );
}

export default App;
